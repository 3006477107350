import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { SectionTitle, Text } from "../../styledComponent";
import {
  TelephoneFill,
  Facebook,
  Instagram,
  Envelope,
  Map,
} from "react-bootstrap-icons";
import { footerNavMenuItems } from "../../../utils/constants";
import footerLogo from "../../../assets/logo.png";
import MenuPDF from "../../../assets/menu.pdf";
import styles from "./style.module.scss";
import { routes } from "../../../utils/routes";

const Footer = () => {
  const handleMenuClick = (e, itemName) => {
    if (itemName === "Menu") {
      e.preventDefault();
      window.open(MenuPDF, "_blank");
    }
  };

  return (
    <footer>
      <Container>
        <Row className={styles.footerInner}>
          <Col lg={3} className={styles.footerColOne}>
            <div className={styles.footerSiteLogo}>
              <img src={footerLogo} alt="Billoos Indian Restaurant" />
            </div>
            <Text>
            Billoo's, an Indian Restaurant in Calgary SW, delights with authentic flavors and diverse dishes. Serving Canyon Meadows, Lake Bonavista, and nearby communities, we specialize in convenient takeout services. From rich curries to fresh-baked bread, enjoy the taste of India in the comfort of your home. Savor tradition, delivered with care!
            </Text>
          </Col>
          <Col lg={4} className={styles.footerColThree}>
            <SectionTitle>Working Hours</SectionTitle>
            <div className={styles.hoursBlock}>
              <div>
                <Text>TUESDAY - SUNDAY</Text>
                <Text>12:00 PM – 09:30 PM</Text>
              </div>
              <div>
                <Text>MONDAY</Text>
                <Text>CLOSED</Text>
              </div>
            </div>
          </Col>
          <Col lg={2} className={styles.footerColTwo}>
            <SectionTitle>Navigation</SectionTitle>
            <ul className={styles.footerProductList}>
              {footerNavMenuItems.map((val, i) => {
                return (
                  <li key={i}>
                    <a
                      href={val.link}
                      onClick={(e) => handleMenuClick(e, val.name)}
                    >
                      <span>{val.name}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col lg={3} className={styles.footerColFour}>
            <SectionTitle>Contact Us</SectionTitle>
            <ul className={styles.contactDetailsBox}>
              <li>
                <a href="mailto:info@billoos.com">
                  <Map />
                  <span>1110 Canterbury Dr SW Unit 3, Calgary, AB T2W 3P5</span>
                </a>
              </li>
              <li>
                <a href="mailto:info@billoos.com">
                  <Envelope />
                  <span>info@billoos.com</span>
                </a>
              </li>
              <li>
                <a href="tel:4034576222">
                  <TelephoneFill />
                  <span>(403) 457-6222</span>
                </a>
              </li>
            </ul>
            <div className={styles.socialIconBox}>
              <div>
                <a href={routes.FB}>
                  <Facebook size={25} />
                </a>
                <a href={routes.INSTAGRAM}>
                  <Instagram size={25} />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className={styles.bottomFooter}>
        <Row>
          <Col lg={6}>
            <Text>Copyright © 2024 Billoo's Indian Cuisine</Text>
          </Col>
          <Col lg={6}>
            <Text>
              Website Design and SEO by{" "}
              <a
                href="https://x360digital.com/"
                target="_blank"
                rel="noreferrer"
              >
                x360 Digital Inc.
              </a>
            </Text>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
