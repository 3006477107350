import { Routes, Route } from "react-router-dom";
import "./App.scss";
import { Header, Footer } from "./components/parts";
import {
  About,
  BilloosCorporate,
  Catering,
  Contact,
  Home,
  PageNotFound,
  Reservation,
  // TakeOutMenu,
} from "./screens";
import { routes } from "./utils/routes";

function App() {
  return (
    <main>
      <Header />
      <Routes>
        <Route path={routes.HOME} element={<Home />} />
        <Route path={routes.ABOUT} element={<About />} />
        <Route path={routes.BILLOOS_CORPORATE} element={<BilloosCorporate />} />
        <Route path={routes.CATERING} element={<Catering />} />
        <Route path={routes.RESERVATION} element={<Reservation />} />
        <Route path={routes.CONTACT} element={<Contact />} />
        {/* <Route path={routes.TAKE_OUT_MENU} element={<TakeOutMenu />} /> */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </main>
  );
}

export default App;
