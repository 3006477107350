import React from "react";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import styles from "./style.module.scss";
import {
  BackgroundOverlay,
  BannerVideoContainer,
  LineDivider,
  PromotionCard,
} from "../../components/ui";
import { Phone } from "react-bootstrap-icons";
import { Container, Row, Col } from "react-bootstrap";
import { Anchor, SectionTitle, Text } from "../../components/styledComponent";
import takeOutImg from "../../assets/takeout.jpg";
import reserveImg from "../../assets/catering.jpg";
import { promotionSliderSettings } from "../../utils/constants";
import { routes } from "../../utils/routes";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          Billoo’s Indian Restaurant in Canyon Meadows, Lake Bonavista, Indian
          Restaurant Calgary SW
        </title>
        <meta
          name="description"
          content="Discover Billoo’s Indian Restaurant in Canyon Meadows and Lake Bonavista, Calgary SW. Enjoy authentic Indian cuisine with convenient takeout options. Order today!"
        />
      </Helmet>
      <div className={styles.homePage}>
        {/* Banner section */}
        <div className={styles.bannerSection}>
          <BannerVideoContainer />
          <BackgroundOverlay />
          <Container className={styles.bannerContent}>
            <Row>
              <Col>
                <SectionTitle>Billoo's Indian Cuisine</SectionTitle>
                <div className={styles.bannerBtn}>
                  <Anchor href="https://www.clover.com/online-ordering/billoos--calgary">
                    ORDER ONLINE
                  </Anchor>
                  <Anchor $secondary href="tel:4034576222" $color="#fff">
                    <Phone />
                    <span>Call Now</span>
                  </Anchor>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Promotion Section */}
        <div className={styles.promotionContainer}>
          <Container className={`sectionContainer ${styles.promotionInner}`}>
            <Row>
              <Col xs={12} md={12} lg={6}>
                <SectionTitle>Check out our Specials & Promotions</SectionTitle>
                <LineDivider />
              </Col>
              <Col xs={12} md={12} lg={6}>
                <Slider
                  {...promotionSliderSettings}
                  className={styles.promotionSliderContainer}
                >
                  <PromotionCard
                    title={"Opening Discount"}
                    description={
                      "Take Enjoy 15% off any order with our Food Takeout."
                    }
                    cta={"Use Promocode: GRANDOPENING"}
                  />
                </Slider>
              </Col>
            </Row>
          </Container>
        </div>

        <div className={styles.reserveDeliverySection}>
          <BackgroundOverlay />
          {/* Take Out Section */}
          <div className={styles.takeOutSection}>
            <Container className={`sectionContainer`}>
              <Row>
                <Col lg={6}>
                  <div className={styles.takeOutContent}>
                    <SectionTitle>TakeOut Services</SectionTitle>
                    <LineDivider />
                    <Text>
                      Enjoy the authentic flavors of India in Calgary with
                      Billoo's Indian Restaurant's takeout menu. From savory
                      curries to aromatic biryanis and tandoori delights, savor
                      the taste of India in the comfort of your home.
                      Conveniently located in Calgary, Billoo's ensures a
                      delightful culinary experience, one takeout order at a
                      time.
                    </Text>
                  </div>
                </Col>
                <Col lg={6}>
                  <img src={takeOutImg} alt="Takeout for Indian Food" />
                </Col>
              </Row>
            </Container>
          </div>

          {/* Reserver table Section */}
          <div className={styles.reserveTableSection}>
            <Container className={`sectionContainer`}>
              <Row>
                <Col lg={6}>
                  <img src={reserveImg} alt="Reserve your Table with us" />
                </Col>
                <Col lg={6}>
                  <div className={styles.takeOutContent}>
                    <SectionTitle>Get Catering Deals</SectionTitle>
                    <LineDivider />
                    <Text>
                      Indulge in the flavors of India with Billoo's Indian
                      Cuisine! Elevate your events with our exquisite catering
                      services in Calgary. For a limited time, enjoy a tempting
                      offer of 10% off on all catering orders. Treat your guests
                      to a culinary journey filled with authentic Indian
                      delights. From savory appetizers to delectable main
                      courses, our menu is sure to impress. Contact us today to
                      spice up your gatherings with Billoo's!
                    </Text>
                    <Anchor href={routes.CATERING}>Order Now</Anchor>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        {/* Why choose us section */}
        <div className={styles.whyChooseUsSection}>
          <div className={styles.whyChoose_one}>
            <BackgroundOverlay color="rgb(0,0,0,0.5)" />
            <div>
              <SectionTitle>Our Core Values</SectionTitle>
              <LineDivider center />
            </div>
          </div>
          <div className={styles.whyChoose_two}>
            <div className={styles.whyChoose_img}></div>
            <div className={styles.whyChooseContent}>
              <SectionTitle>100% Fresh Products</SectionTitle>
              <LineDivider center />
              <Text>
                Our focus has always been having the freshest produce, including
                local,wesern and Canada-wide growers, whenever possible. We
                ensure that seasonal produce is available and that it is
                imported based on quality, freshness and availability.
              </Text>
            </div>
          </div>
          <div className={styles.whyChoose_three}>
            <div className={styles.whyChooseContent}>
              <SectionTitle>Delicious Food</SectionTitle>
              <LineDivider center />
              <Text>
                Deliciously made by our expert Chefs to satisfy the taste buds .
                A Perfect blend of spices with portions
              </Text>
            </div>
            <div className={styles.whyChoose_img}></div>
          </div>
          <div className={styles.whyChoose_four}>
            <div className={styles.whyChoose_img}></div>
            <div className={styles.whyChooseContent}>
              <SectionTitle>Friendly Staff</SectionTitle>
              <LineDivider center />
              <Text>
                Billoo’s believe ” YOU DON’T BUILD A BUSINESS YOU BUILD PEOPLE
                And then PEOPLE BUILD THE BUSINESS “
              </Text>
            </div>
          </div>
          <div className={styles.whyChoose_five}>
            <div className={styles.whyChooseContent}>
              <SectionTitle>Relaxing Atmosphere</SectionTitle>
              <LineDivider center />
              <Text>
                As we express our gratitude, we believe that the highest
                appreciation is not to utter words, but to bring smile to our
                customers by giving Relaxed atmosphere
              </Text>
            </div>
            <div className={styles.whyChoose_img}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
